import { usePrevious } from '../hooks/use-previous.hook'
import React, { createContext, useContext } from 'react'
import { useLocation } from 'react-router-dom'

const PreviousURLContext = createContext()

export const usePreviousURLContext = () => useContext(PreviousURLContext)

const PreviousURLProvider = ({ children }) => {
  const { pathname } = useLocation()
  const previousURL = usePrevious(pathname)

  return <PreviousURLContext.Provider value={previousURL}>{children}</PreviousURLContext.Provider>
}

export default PreviousURLProvider
