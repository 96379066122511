import axiosInstance from './axiosInstance'

export const getServices = async churchId => {
  const response = await axiosInstance.get(`/church/${churchId}/services/`)

  return response.data
}

export const createPayment = async (churchId, serviceId, data) => {
  const response = await axiosInstance.post(`/church/${churchId}/service/${serviceId}/`, data)

  return response.data
}

export const getBalance = async (email) => {
  const response = await axiosInstance.post(`/balance_of_good/get_balance/?email=${email}`)

  return response.data
}
