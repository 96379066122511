import { useDispatch } from 'react-redux'
import Layout from '../src/components/Layout'
import { lazy, Suspense, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import RejectPayment from '../src/pages/RejectPayment'
import SuccessPayment from '../src/pages/SuccessPayment'
import { setLanguageLoaded } from './store/slices/user-slice'
import RejectLiveStreaming from './pages/RejectLiveStreaming'
import { setLanguage } from './store/slices/language-slice'
import Notification from './utils/firebase/Notification'
import Auth from './pages/Auth'
import Redirect from './components/Redirect'

const Terms = lazy(() => import(/* webpackChunkName: 'terms' */ '../src/pages/Terms'))
const Cookies = lazy(() => import(/* webpackChunkName: 'cookies' */ '../src/pages/Cookies'))
const Policy = lazy(() => import(/* webpackChunkName: 'policy' */ '../src/pages/Policy'))
const Payment = lazy(() => import(/* webpackChunkName: 'payment' */ '../src/pages/Payment'))
const List = lazy(() => import(/* webpackChunkName: 'list' */ '../src/pages/List'))
const Contact = lazy(() => import(/* webpackChunkName: 'contact' */ '../src/pages/Contact'))
const ViewItem = lazy(() => import(/* webpackChunkName: 'view-item' */ '../src/pages/ViewItem'))
const NFT = lazy(() => import(/* webpackChunkName: 'favorite' */ '../src/pages/NFT'))
const Order = lazy(() => import(/* webpackChunkName: 'order' */ '../src/pages/Order'))
const LiveStream = lazy(() => import(/* webpackChunkName: 'live' */ '../src/pages/Live'))
const Balance = lazy(() => import(/* webpackChunkName: 'meeting' */ './pages/Balance'))
const ListNotifications = lazy(() =>
  import(/* webpackChunkName: 'meeting' */ './pages/ListNotifications')
)
const ViewNotification = lazy(() =>
  import(/* webpackChunkName: 'meeting' */ './pages/ViewNotification')
)
const StreamContainer = lazy(() =>
  import(/* webpackChunkName: 'index' */ './components/StreamContainer')
)
const Meeting = lazy(() => import(/* webpackChunkName: 'meeting' */ './pages/Meeting'))

function App() {
  const dispatch = useDispatch()
  const [_, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1200)
  }, [])

  useEffect(() => {
    if (!localStorage.getItem('language')) {
      if (window.navigator?.language?.split('-')[0] === 'en') {
        localStorage.setItem('language', 'en')
        dispatch(setLanguage('en'))
      } else {
        localStorage.setItem('language', 'ua')
        dispatch(setLanguage('ua'))
      }
    }

    dispatch(setLanguage(localStorage.getItem('language')))
    dispatch(setLanguageLoaded())
  }, [])

  return (
    <Suspense fallback={null}>
      <Switch>
        <Route exact path={'/success-payment'} component={SuccessPayment} />
        <Route exact path={'/reject-payment'} component={RejectPayment} />
        <Route exact path={'/payment'} component={Payment} />
        <Route exact path={'/reject-liveStreaming'} component={RejectLiveStreaming} />
        <Route exact path={'/liveStreaming/meeting/:churchId'} component={Meeting} />
        <Route exact path={'/auth'} component={Redirect} />
        <Route exact path={'/authorization'} component={Auth} />
        <Layout>
          <Suspense fallback={null}>
            <Route exact path={'/contact'} component={Contact} />
            <Route exact path={'/dao'} component={Contact} />
            <Route exact path={'/'} component={List} />
            <Route exact path={'/nft'} component={NFT} />
            <Route exact path={'/item/:churchId'} component={ViewItem} />
            <Route exact path={'/item/:church_id/services/:service_id'} component={Order} />
            <Route exact path={'/terms'} component={Terms} />
            <Route exact path={'/cookies'} component={Cookies} />
            <Route exact path={'/policy'} component={Policy} />
            <Route exact path={'/live'} component={LiveStream} />
            <Route exact path={'/balance'} component={Balance} />
            <Route exact path={'/notifications/:notification_id'} component={ViewNotification} />
            <Route exact path={'/notifications'} component={ListNotifications} />
            <Route path={'/live/streaming'} component={StreamContainer} />
          </Suspense>
        </Layout>
      </Switch>
      <Notification />
    </Suspense>
  )
}

export default App
