import { createTheme, MuiThemeProvider, responsiveFontSizes } from '@material-ui/core'
import { store } from './store'
import { Provider } from 'react-redux'
import PreviousURLProvider from '../src/contexts/previous-url.context'
import AllowCookiesDialog from '../src/components/AllowCookiesDialog'
import React, { useLayoutEffect } from 'react'
import * as serviceWorker from './serviceWorker'
import { render } from 'react-dom'
import { useLocation, BrowserRouter } from 'react-router-dom'
import App from './App'
import { ContainerProvider } from './utils/container'

const globalTheme = createTheme({
  palette: {
    primary: { main: '#bd23bc' },
    secondary: { main: '#6b0aab' },
    text: { primary: '#0f2344' },
    background: { primary: '#110524' },
  },
  typography: { fontFamily: 'Nunito Sans' },
})

const theme = responsiveFontSizes(
  createTheme({
    ...globalTheme,
    overrides: {
      MuiButton: {
        containedPrimary: {
          color: 'white',
          '&:hover': {
            backgroundColor: globalTheme.palette.primary.light,
          },
          '&:active': {
            boxShadow: 'none',
          },
        },
        root: {
          textTransform: 'none',
          fontSize: '1rem',
          fontWeight: 600,
          lineHeight: '24px',
          borderRadius: '50rem',
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: '50rem',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${globalTheme.palette.primary.light}`,
          },
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },

          '& input[type=number]': {
            '-moz-appearance': 'textfield',
          },
        },
      },
    },
    props: {
      MuiTypography: {
        color: 'textPrimary',
      },
      MuiButtonBase: {
        disableRipple: true,
      },
      MuiTextField: {
        variant: 'outlined',
      },
      MuiButton: {
        size: 'large',
        color: 'primary',
        variant: 'contained',
      },
      MuiUseMediaQuery: {
        noSsr: true,
      },
    },
  })
)

function ScrollToTop() {
  const { pathname } = useLocation()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PreviousURLProvider>
          <ScrollToTop />
          <MuiThemeProvider theme={theme}>
            <AllowCookiesDialog />
            <ContainerProvider>
              <App />
            </ContainerProvider>
          </MuiThemeProvider>
        </PreviousURLProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
