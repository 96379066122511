import axiosInstance from './axiosInstance'

export const getFavoriteDetail = async (churchId, visitorId) => {
  try {
    const response = await axiosInstance.post(`favorite/detail/`, {
      device_id: visitorId,
      church_id: churchId,
    })

    return response.data
  } catch (e) {
    return e.response.data
  }
}

export const like = async (churchId, visitorId, isLiked) => {
  if (isLiked) {
    await axiosInstance.post(`/favorite/`, {
      device_id: visitorId,
      church_id: churchId,
    })
  } else {
    await axiosInstance.delete(`/favorite/`, {
      data: { device_id: visitorId, church_id: churchId },
    })
  }
}

export const getFavoriteChurches = async visitorId => {
  const response = await axiosInstance.post(`/favorite/list/`, {
    device_id: visitorId,
  })

  return response.data
}
