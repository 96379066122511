import axiosInstance from './axiosInstance'

export const saveToken = async (token) => {
    const response = await axiosInstance.post(`/messaging/token/create/`, {
        content: token
    })
    return response.data
}

export const getNotifications = async (pageId) => {
    const response = await axiosInstance.get(`/messaging/notifications/?token=${localStorage.getItem("token")}&page=${pageId}`)

    return response.data
}

export const getNotification = async (id) => {
    const response = await axiosInstance.get(`/messaging/notifications/?token=${localStorage.getItem("token")}&id=${id}`)

    return response.data
}

export const getNewNotifications = async () => {
    const response = await axiosInstance.get(`/messaging/notifications/?token=${localStorage.getItem("token")}&new=1`)

    return response.data
}

export const getNotificationByPackId = async (packId) => {
    const response = await axiosInstance.get(`/messaging/notifications/?token=${localStorage.getItem("token")}&pack_id=${packId}`)

    return response.data
}

export const setNotificationViewed = async (id) => {
    const response = await axiosInstance.post(`/messaging/notifications/view/${id}/`)

    return response.data
}
