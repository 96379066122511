import {Box, Link, makeStyles, Typography, useMediaQuery} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import Chip from '../components/Chip'
import {useSelector} from "react-redux";
import team2 from "../assets/team/6.png";

const useStyles = makeStyles(theme => {
  return {
    successLabel: {
      ...theme.typography.h5,
      fontWeight: theme.typography.fontWeightBold,
      padding: '1.5rem',
    },
  }
})

const SuccessPayment = () => {
  const classes = useStyles()
  const [churchId, setChurchId] = useState(null)
  const language = useSelector(state => state.language.language)
  const initLanguage = useSelector(state => state.user.isLanguageLoaded)
  const smUp = useMediaQuery(theme => theme.breakpoints.up("sm"))

  useEffect(() => {
    const orderId = localStorage.getItem('order_id')
    if (!orderId) return

    fetch(`https://religionpeace.live/api/service/order/${orderId}`)
      .then(res => res.json())
      .then(order => {
        setChurchId(order.church_uuid)
      })
  }, [])

  return (
      <>
        {initLanguage && (
            <Box p={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Chip className={classes.successLabel}>{language === "ua" ? "Дякуємо!" : "Thank you!"}</Chip>
                <Typography align={'center'} variant={'h6'}>
                    {language === "ua" ? "На Вашу електронну пошту прийде лист про статус платежу." :
                        "A letter about the payment status will be sent to your email."}
                </Typography>
                <Link component={RouterLink} to={churchId ? `/item/${churchId}` : '/'} underline={'always'}>
                    <Typography style={{color: '#f87f30'}}>
                        {language === "ua" ? "повернутися на початкову сторінку…" : "Return to the initial page..."}
                    </Typography>
                </Link>
                <img src={team2} alt={'team2'} style={smUp ? {maxWidth: '30%', marginTop: '100px', aspectRatio: 1} : {maxWidth: '80%', marginTop: '40px', height: '170px'}} />
            </Box>
        )}
      </>
  )
}

export default SuccessPayment
