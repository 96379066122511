import { createAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  churches: [],
  loading: true,
}

export const fetchFavoriteChurches = createAction('favorite-churches-list/fetch-churches')

export const favoriteChurchesList = createSlice({
  name: 'favorite-churches-list',
  initialState,
  reducers: {
    setChurches(state, { payload }) {
      state.churches = payload
    },
    setLoading(state, { payload }) {
      state.loading = payload
    },
  },
})

export const { setChurches, setLoading } = favoriteChurchesList.actions

export default favoriteChurchesList.reducer
