import axiosInstance from './axiosInstance'

export const requestForSyncDevice = async (email, token) => {
    const response = await axiosInstance.post(`/balance_of_good/sync_device/`, { email, token })

    return response.data
}

export const confirmSyncDevice = async (email, token, code) => {
    const response = await axiosInstance.post(`/balance_of_good/sync_device/`, { email, token, code})

    return response.data
}

export const checkAuth = async () => {
    const response = await axiosInstance.post('/balance_of_good/check_sync/', {
        token: localStorage.getItem("token")
    })

    return response.data
}

export const checkSubscribe = async (uuid) => {
    const response = await axiosInstance.post('/balance_of_good/check_sync/', {
        token: localStorage.getItem("token"),
        church: uuid
    })

    return response.data
}

export const deSyncDevice = async () => {
    const response = await axiosInstance.post('/balance_of_good/desync/', {
        token: localStorage.getItem("token")
    })

    return response.data
}

export const deleteAccount = async () => {
    const response = await axiosInstance.post('/balance_of_good/delete_account/', {
        token: localStorage.getItem("token")
    })

    return response.data
}
