import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: 'https://coindefibank.com/api/',
})

axiosInstance.interceptors.request.use(function (config) {
  config.headers['Accept-Language'] = localStorage.getItem('language')

  return config
})

export default axiosInstance
