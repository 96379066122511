import { batchActions } from 'redux-batched-actions'
import { ofType } from 'redux-observable'
import { concat, merge, of } from 'rxjs'
import { fromPromise } from 'rxjs/internal-compatibility'
import { concatMap, debounceTime, filter, map, mapTo, mergeMap, takeUntil } from 'rxjs/operators'
import { getChurches, getChurchesByRadius } from '../../api/churchAPI'
import { getCurrentPosition } from '../../utils/geolocation'
import {
  addChurches,
  debouncedFetchMoreChurches,
  fetchMoreChurches,
  incrementPage,
  setChurches,
  setHasMore,
  setInitialState,
  setLoading,
  setRadius,
  setRadiusAsync,
  setTerm,
  setTermAsync,
} from '../slices/churches-list-slice'

export const fetchMoreChurchesEpic = (action$, state$) =>
  action$.pipe(
    ofType(fetchMoreChurches.type),
    filter(() => state$.value.churchesList.hasMore && state$.value.churchesList.radius === 0),
    mergeMap(() =>
      concat(
        of(setLoading(true)).pipe(filter(() => state$.value.churchesList.page === 1)),
        fromPromise(
          getChurches(state$.value.churchesList.page, state$.value.churchesList.term)
        ).pipe(
          concatMap(({ results, next }) =>
            concat(
              of(setLoading(false)).pipe(filter(() => state$.value.churchesList.page === 1)),
              of(batchActions([setHasMore(!!next), addChurches(results), incrementPage()]))
            )
          ),
          takeUntil(action$.pipe(ofType(setTermAsync.type)))
        )
      )
    )
  )
export const debouncedFetchMoreChurchesEpic = action$ =>
  action$.pipe(
    ofType(debouncedFetchMoreChurches.type),
    debounceTime(200),
    mapTo(fetchMoreChurches())
  )

export const setTermAsyncEpic = action$ =>
  action$.pipe(
    ofType(setTermAsync.type),
    mergeMap(({ payload }) =>
      concat(
        of(batchActions([setInitialState(), setTerm(payload)])),
        of(debouncedFetchMoreChurches())
      )
    )
  )

export const setRadiusAsyncEpic = action$ =>
  action$.pipe(
    ofType(setRadiusAsync.type),
    mergeMap(({ payload: newRadius }) =>
      merge(
        of(batchActions([setInitialState(), setRadius(newRadius)])),
        newRadius > 0
          ? fromPromise(getCurrentPosition()).pipe(
              concatMap(({ coords: { longitude, latitude } }) =>
                fromPromise(getChurchesByRadius(newRadius, longitude, latitude))
              ),
              map(churches =>
                batchActions([setLoading(false), setChurches(churches), setHasMore(false)])
              )
            )
          : merge(of(setInitialState()), of(fetchMoreChurches()))
      )
    )
  )
