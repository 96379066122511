import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    countNewNotifications: 0
}

export const notificationSlice = createSlice({
    name: 'notifications',
    initialState: initialState,
    reducers: {
        setCountNewNotifications(state, { payload : { count } }) {
            state.countNewNotifications = count
        },
    }
})

export const {
    setCountNewNotifications
} = notificationSlice.actions

export default notificationSlice.reducer
