import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import React, { memo, useEffect, useState } from 'react'
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'
import Divider from './Divider'
import { useSelector } from 'react-redux'

const Footer = () => {
  const history = useHistory()
  const location = useLocation()
  const xsMatch = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const smMatch = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const initLanguage = useSelector(state => state.user.isLanguageLoaded)
  const language = useSelector(state => state.language.language)
  const [routes, setRoutes] = useState([])
  const [isNFT, setIsNFR] = useState(true)

  useEffect(() => {
    setIsNFR(!(history.location.pathname === '/nft'))
  }, [location.pathname])

  useEffect(() => {
    if (initLanguage) {
      if (language === 'en') {
        setRoutes([
          { to: '/terms', name: 'Terms of Use' },
          { to: '/policy', name: 'Privacy Policy' },
          { to: '/cookies', name: 'Cookies' },
        ])
      } else {
        setRoutes([
          { to: '/terms', name: 'Правила користування' },
          { to: '/policy', name: 'Політика конфіденційності' },
          { to: '/cookies', name: 'Файли Cookies' },
        ])
      }
    }
  }, [initLanguage])

  return (
    <>
      {initLanguage && (
        <Box
          component={'footer'}
          p={2}
          pt={isNFT ? 4 : 2}
          style={{ background: '#110524', color: 'white', marginTop: 'auto' }}
        >
          <Container>
            {isNFT && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={7}>
                    <Typography
                      variant={'h6'}
                      style={{
                        opacity: 0.8,
                        color: 'white',
                        ...(xsMatch && { textAlign: 'center' }),
                      }}
                    >
                      <strong>
                        {language === 'ua'
                          ? 'Купи NFT терміналу для збору монет та отримуй CDFB токени з кожного поповнення!'
                          : 'Buy Coin Collecting Machine NFT and get CDFB tokens from every top-up!'}
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      ...(xsMatch && { justifyContent: 'center' }),
                    }}
                  >
                    <Button component={RouterLink} to={`/nft`}>
                      {language === 'ua' ? 'Купити NFT' : 'Buy NFT'}
                    </Button>
                  </Grid>
                </Grid>
                <Divider style={{ margin: '1.5rem 0' }} />
              </>
            )}
            <Grid container {...(smMatch && { wrap: 'wrap-reverse' })} spacing={2}>
              <Grid
                item
                xs={12}
                md={6}
                style={{ ...(smMatch && { display: 'flex', justifyContent: 'center' }) }}
              >
                <Typography variant={'caption'} style={{ color: 'white' }}>
                  <strong> &copy; CoinDeFiBank, {new Date().getFullYear()}. </strong>
                  {language === 'ua' ? 'Всі права захищено.' : 'All rights reserved.'}
                </Typography>
              </Grid>
              <Grid item sm={12} md={6}>
                <nav>
                  <List
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                      padding: 0,
                    }}
                  >
                    {routes.map(({ to, name }, i) => (
                      <ListItem key={i} style={{ width: 'auto', paddingTop: 0, paddingBottom: 0 }}>
                        <ListItemText>
                          <Link
                            underline={'hover'}
                            component={RouterLink}
                            to={to}
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {name}
                          </Link>
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </nav>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  )
}

export default memo(Footer)
