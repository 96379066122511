import qs from 'qs'
import axiosInstance from './axiosInstance'

export const getChurches = async (page, term) => {
  const response = await axiosInstance.get(`/search/church?${qs.stringify({ page, q: term })}`)

  return response.data
}

export const getChurchesByRadius = async (km, longitude, latitude) => {
  const response = await axiosInstance.get(
    `https://religionpeace.live/api/church/nearest?${qs.stringify({
      lat: longitude,
      lon: latitude,
      radius_km: km,
    })}`
  )

  return response.data
}

export const getChurch = async churchId => {
  const response = await axiosInstance.get(`/church/${churchId}/`)

  return response.data
}
